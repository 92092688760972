import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="center">
      Pace Engineering GmbH <br/>
      Zugerstrasse 32 <br/>
      6340 Baar
    </div>
  );
}

export default App;
